/* @import url('https://fonts.googleapis.com/css?family=Lato:100&display=swap'); */



.fbtn-container {
  width: 400px;
  height: 400px;
  position: absolute;
  /* left: 50%;
  top: 50%; */
  transform: translate(-25%, 0%);
  display: flex;
  justify-content: center;
  align-items: center;
  
}

@media (min-width: 320px) {
  .fbtn-container {
    position: absolute;
    transform: translate(-25%, -20%);
  }
}

@media (min-width: 641px) {
  .fbtn-container {
    position: absolute;
    transform: translate(-25%, 0%);
  }
}

@media (min-width: 961px) {
  .fbtn-container {
    position: absolute;
    transform: translate(-25%, 10%);
  }
}

@media (min-width: 961px) {
  .fbtn-container {
    position: absolute;
    transform: translate(-25%, 20%);
  }
}

.center {
  width: 180px;
  height: 60px;
  position: absolute;
}

#fbtn {
  width: 180px;
  height: 60px;
  cursor: pointer;
  background: transparent;
  border: 3px solid #b5ff0893;
  outline: none;
  transition: 1s ease-in-out;
  /* border-radius: 20%; */
  
}

#fbtn-svg {
  position: absolute;
  
  left: 0;
  top: 0;
  fill: none;
  stroke: #fff;
  stroke-dasharray: 150 480;
  stroke-dashoffset: 150;
  transition: 1s ease-in-out;
  /* border-radius: 20%; */
}

#fbtn:hover {
  transition: 1s ease-in-out;
  /* background: #b5ff0893; */
}

#fbtn:hover #fbtn-svg {
  stroke-dashoffset: -480;
}

#fbtn span {
  color: white;
  font-size: 18px;
  font-weight: 100;
}