/* sonar effect */
.blurb-ripple-out {
  display: inline-block;
  font-size: 0px;
  cursor: pointer;
  margin: 5px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  text-align: center;
  position: relative;
  z-index: 1;
  color: #fff;
  background: #ffffff;
  -webkit-transition: -webkit-transform ease-out 0.1s, background 0.2s;
  -moz-transition: -moz-transform ease-out 0.1s, background 0.2s;
  transition: transform ease-out 0.1s, background 0.2s;
}

@media (min-width: 768px) {
  .blurb-ripple-out {
    margin: 10px;
  }
}

.blurb-ripple-out-wrap {
  text-align: center;
  margin: 0 auto;
  padding: 1em 0 3em;
}

.blurb-ripple-out:after {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: "";
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  top: 0;
  left: 0;
  padding: 0;
  z-index: -1;
  box-shadow: 0 0 0 2px rgba(178, 221, 76, 1);
  opacity: 0;
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
}

.blurb-ripple-out:before {
  speak: none;
  font-size: 30px;
  line-height: 70px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  display: block;
  -webkit-font-smoothing: antialiased;
  padding-bottom: 10px;
}

.blurb-ripple-out:hover {
  background: rgba(93, 220, 255, 1);
  -webkit-transform: scale(0.93);
  -moz-transform: scale(0.93);
  -ms-transform: scale(0.93);
  transform: scale(0.93);
  color: #fff;
}

.blurb-ripple-out:hover:after {
  -webkit-animation: sonarEffect 1.3s ease-out 75ms;
  -moz-animation: sonarEffect 1.3s ease-out 75ms;
  animation: sonarEffect 1.3s ease-out 75ms;
}

@keyframes sonarEffect {
  0% {
    opacity: 0.5;
  }
  40% {
    opacity: 0.7;
    box-shadow: 0 0 0 2px rgba(93, 220, 255, 1),
      0 0 10px 10px rgba(93, 220, 255, 1), 0 0 0 10px rgba(93, 220, 255, 1);
  }
  100% {
    box-shadow: 0 0 0 2px rgba(93, 220, 255, 1),
      0 0 10px 10px rgba(93, 220, 255, 1), 0 0 0 10px rgba(93, 220, 255, 1);
    transform: scale(1.5);
    opacity: 0;
  }
}

a {
  text-decoration: none;
}

/* blinking effect */
@keyframes blink-outline {
  0%,
  100% {
    outline-color: transparent;
  }
  50% {
    outline-color: #fce903;
  }
}

@keyframes bounce {
  0%,
  6%,
  10%,
  14%,
  100% {
    transform: translateY(0);
  }
  4% {
    transform: translateY(-12px);
  }
  8% {
    transform: translateY(-10px);
  }
  12% {
    transform: translateY(-5px);
  }
}

.button-blink-bounce {
  animation: blink-outline 1s linear infinite, bounce 5s infinite;
  outline: 3px solid transparent;
}
