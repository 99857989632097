@import url("//maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css");

#projectsBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  right: 20px;
  /* min-height: 100vh; */
  /* background: #00071c; */
}
#projectsBtn-ul {
  position: relative;
  display: flex;
  transform: rotate(-25deg) skew(25deg);
  transform-style: preserve-3d;
}
#projectsBtn-ul li {
  position: relative;
  list-style: none;
  width: 40px;
  height: 40px;
  margin: 0px 0px;
}
#projectsBtn-ul li:before {
  content: "";
  position: absolute;
  bottom: -10px;
  /* left: -5px; */
  width: 100%;
  height: 10px;
  background: purple;
  transform-origin: top;
  transform: skewX(-41deg);
}
#projectsBtn-ul li:after {
  content: "";
  position: absolute;
  top: 5px;
  left: -9px;
  width: 9px;
  height: 100%;
  background: purple;
  trnasform-origin: right;
  transform: skewY(-49deg);
}
#projectsBtn-ul li span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex !important;
  background: purple;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 30px !important;
  transition: 1.5s ease-out;
}
#projectsBtn-ul li:hover span {
  z-index: 1000;
  transition: 0.3s;
  color: #fff;
  box-shadw: -1px 1px 1px rgba(0, 0, 0, 0.5);
}
#projectsBtn-ul li:hover span:nth-child(5) {
  transform: translate(40px, -40px);
  opacity: 1;
}
#projectsBtn-ul li:hover span:nth-child(4) {
  transform: translate(30px, -30px);
  opacity: 0.8;
}
#projectsBtn-ul li:hover span:nth-child(3) {
  transform: translate(20px, -20px);
  opacity: 0.6;
}
#projectsBtn-ul li:hover span:nth-child(2) {
  transform: translate(10px, -10px);
  opacity: 0.4;
}
#projectsBtn-ul li:hover span:nth-child(1) {
  transform: translate(0px, 0px);
  opacity: 0.2;
}
#projectsBtn-ul li:nth-child(1):hover span {
  background: purple !important;
}
#projectsBtn-ul li:nth-child(2):hover span {
  background: #2c3456 !important;
}
#projectsBtn-ul li:nth-child(3):hover span {
  background: #ea6e96 !important;
}
#projectsBtn-ul li:nth-child(4):hover span {
  background: #fceb00 !important;
}
