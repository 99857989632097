.content {
	position: relative;	
}

.content h2 {
	/* color: #fff; */
	/* font-size: 80vh; */
	position: absolute;
	-webkit-text-stroke: 5px #000000;
    
}

@media (min-width: 320px) {
	.content h2 {
		font-size: 12vw;
		-webkit-text-stroke: 2px #000000;
  }
}

@media (min-width: 481px) {
	.content h2 {
		-webkit-text-stroke: 3px #000000;
  }
}

@media (min-width: 641px) {
	.content h2 {
		-webkit-text-stroke: 5px #000000;
  }
}



.content h2:nth-child(1) {
	color: transparent;
	
	
    
}

.content h2:nth-child(2) {
	color: #b5ff08;
	
	animation: animate 4s ease-in-out infinite;
	/* font-size: 10vw; */
    /* text-shadow: rgb(255, 255, 255) 10px 10px 10px; */
	
}

@keyframes animate {
	0%,
	100% {
		clip-path: polygon(
			0% 45%,
			16% 44%,
			33% 50%,
			54% 60%,
			70% 61%,
			84% 59%,
			100% 52%,
			100% 100%,
			0% 100%
		);
	}

	50% {
		clip-path: polygon(
			0% 60%,
			15% 65%,
			34% 66%,
			51% 62%,
			67% 50%,
			84% 45%,
			100% 46%,
			100% 100%,
			0% 100%
		);
	}
}

#welcome-sign {
	-webkit-text-stroke: 1px #ffffff;
}


.wrapper {
	
    padding-top: 50px;
    font-family: 'Bungee', cursive;
    background-image: url('./background.jpg');
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center;
    height: 100vh;
    background-size: cover;
}

.header-text {
    font-weight: 900;
    color: rgb(0, 0, 0);
	text-align: left;
	
	padding-left: 1%;
	font-size: 7vw;
	
}

@media (min-width: 1024px) {
	.header-text {
		font-size: 4vw;
	}
}

.appear {
	-webkit-text-stroke: 3px #000000;
	color: #ffffff;
    transform-origin: center top;
}

/* 핸드폰 화면 크기에 따른 미디어 쿼리 */
@media (max-width: 600px) {
    .appear {
        -webkit-text-stroke: 2px #000000;
    }
}

.d1 {
    animation-delay: 1s;
}

.d2 {
    animation-delay: 2s;
}

.d3 {
    animation-delay: 3s;
}

.d4 {
    animation-delay: 4s;
}

.d8 {
    animation-delay: 5s;
}

@keyframes show {
    0% {
        transform: translate(0, 2em);
        opacity: 0;
        filter: brightness(1.75);
    }

    50% {
        filter: brightness(1.75);
    }

    100% {
        transform: translate(0, 0);
        opacity: 1;
        text-shadow: none;
    }
}

@keyframes blink-portfolio {
	0%, 100% {
	  outline-color: transparent;
	}
	50% {
	  outline-color: #e9fb8b;
	}
}

.blink-portfolio {
	animation: blink-portfolio 2s linear infinite;
	outline: 3px solid transparent;
}

.to-main:hover {
	transition: ease-in-out 0.5s;
	filter: brightness(1.3);
	animation: shake 3s infinite;
}