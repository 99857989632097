body {
	background: #c9ebf5;
}

.logo:hover {
    animation: shake 3s infinite;
}

@keyframes shake {
	0%{
		transform: translate(1px, 1px) rotate(0deg);
	}
	10%{
		transform: translate(-1px, -1px) rotate(1deg);
	}
	20%{
		transform: translate(-2px, 1px) rotate(-1deg);
	}
	30%{
		transform: translate(1px, 2px) rotate(0deg);
	}
	40%{
		transform: translate(-1px, 1px) rotate(1deg);
	}
	50%{
		transform: translate(1px, -1px) rotate(-2deg);
	}
	60%{
		transform: translate(2px, 1px) rotate(1deg);
	}
	70%{
		transform: translate(-1px, 2px) rotate(2deg);
	}
	80%{
		transform: translate(2px, -1px) rotate(1deg);
	}
	90%{
		transform: translate(-1px, 2px) rotate(0deg);
	}
	100%{
		transform: translate(2px, 1px) rotate(-1deg);
	}
}
