.about {
    background-color: #edf7fc;
    padding-bottom: 40px;
}








